import { mainPermissions } from "../../util/MenuLinks";
import UserHavePermission from "../../util/userHavePermissions";

const CostCentersModulesActionsComponent = () => {
    return (
        <div>
            {
                UserHavePermission(mainPermissions?.costCenters?.[1]) &&
                <div className="text-end">
                    <a href="/centros-de-costos/importar-haberes" className="btn btn-success">
                        Importar configuración de haberes
                    </a>
                    <a href="/centros-de-costos/importar-descripcion-de-los-haberes" className="btn btn-success ms-1">
                        Importar descripciones de los haberes
                    </a>
                </div>
            }
        </div>
    )
}

export default CostCentersModulesActionsComponent;