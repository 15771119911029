import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import useAxios from "../../hooks/useAxios";

const CostCenterItemDescriptionsComponent = ({ costCenterId }) => {

    const [{ data: descriptionsData, loading: loadingDescriptions }, getDescriptions] = useAxios({ url: `/cost-center-item-descriptions` }, { useCache: false, manual: true });

    const [{ loading: loadingStoreDescriptions }, storeDescriptions] = useAxios({ url: `/cost-center-item-descriptions`, method: 'POST' }, { useCache: false, manual: true });

    const [show, setShow] = useState(false);

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (show) {
            getDescriptions({ params: { cost_center_id: costCenterId } });
        }
    }, [show])

    const [data, setData] = useState({
        item_code: '',
        description: '',
        cost_center_id: costCenterId
    });

    const handleChange = (e) => {
        setData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleAdd = () => {
        setData({ item_code: '', description: '', cost_center_id: costCenterId });
        setShowModal(true);
    }

    const handleUpdate = (description) => {
        setData({ item_code: description?.item_code, description: description?.description, cost_center_id: costCenterId });
        setShowModal(true);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        storeDescriptions?.({ data }).then(() => {
            setShowModal(false);
            getDescriptions();
        });
    }

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <div className="d-flex w-100 align-items-center">
                        <h4>
                            Descripciones de los haberes
                        </h4>
                        <button className="btn btn-xs btn-primary ms-auto" onClick={() => setShow(oldValue => !oldValue)}>
                            {
                                show ?
                                    <FaChevronUp />
                                    :
                                    <FaChevronDown />
                            }
                        </button>
                    </div>
                </div>
                {
                    show &&
                    <div className="card-body">
                        {
                            loadingDescriptions ?
                                <div className="spinner">
                                    <div className="double-bounce1 bg-primary"></div>
                                    <div className="double-bounce2 bg-primary"></div>
                                </div>
                                :
                                <>
                                    <div className="text-center">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Haber
                                                        </th>
                                                        <th>
                                                            Descripción
                                                        </th>
                                                        <th>
                                                            Acciones
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        descriptionsData?.length > 0 ?
                                                            descriptionsData?.map((description) => {
                                                                return (
                                                                    <tr key={`descripciones-${description?.id}`}>
                                                                        <td>
                                                                            {description?.item_code}
                                                                        </td>
                                                                        <td>
                                                                            {description?.description}
                                                                        </td>
                                                                        <td>
                                                                            <button onClick={() => handleUpdate(description)} type="button" className="btn btn-primary btn-sm">
                                                                                Editar
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                            :
                                                            <tr>
                                                                <td colSpan={3} className="text-center">
                                                                    No se encontrarón resultados.
                                                                </td>
                                                            </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <button className="btn btn-success" onClick={handleAdd}>
                                            Agregar
                                        </button>
                                    </div>
                                </>
                        }
                    </div>
                }
            </div>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Agregar descripción del item
                    </Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <input type="text" className="form-control" placeholder="Código del haber" value={data?.item_code} name="item_code" onChange={handleChange} />
                            </div>
                            <div className="col-md-12 mb-3">
                                <textarea
                                    name="description"
                                    onChange={handleChange}
                                    value={data?.description}
                                    className="form-control"
                                    placeholder="Descripción"
                                    style={{ minHeight: 200 }}
                                ></textarea>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            Cerrar
                        </Button>
                        <button className="btn btn-primary" disabled={loadingStoreDescriptions}>
                            {
                                loadingStoreDescriptions ?
                                    'Cargando'
                                    :
                                    'Guardar Cambios'
                            }
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default CostCenterItemDescriptionsComponent;