import { useParams } from "react-router-dom"
import useAxios from "../../../hooks/useAxios";
import { FaRegUser, FaProjectDiagram, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useEffect, useState } from "react";
import profileImg from '../../../images/profile.png';
import { Button, Dropdown, Modal, Table } from "react-bootstrap";
import { useFeedBack } from "../../../context/FeedBackContext";
import { monthNames, periodTypeDescription } from "../../../util/consts";
import RenderStatus from "../../../components/RenderStatus";
import ChangePayrrolStatusComponent from "../../../components/Payrrols/ChangePayrrolStatusComponent";
import PayrrolResponsibleComponent from "../../../components/PayrrolResponsible/PayrrolResponsibleComponent";
import update from 'immutability-helper';
import CostCenterNotificationEmailsComponent from "../../../components/CostCenters/CostCenterNotificationEmailsComponent";
import CostCenterItemDescriptionsComponent from "../../../components/CostCenters/CostCenterItemDescriptionsComponent";

const CostCentersUpdate = () => {

    const { setLoading } = useFeedBack();

    const { id } = useParams();

    const [currentWorkers, setCurrentWorkers] = useState([]);

    const [currentUsers, setCurrentUsers] = useState([]);

    const [currentPayrrols, setCurrentPayrrols] = useState([]);

    const [currentResponsibles, setCurrentResponsibles] = useState([]);

    const [selectedPayrrol, setSelectedPayrrol] = useState(null);

    const [showAddResponsiblesModal, setShowAddResponsiblesModal] = useState(false);

    const [showCreateProccessModal, setShowCreateProccessModal] = useState(false);

    const [showAllWorkersModal, setShowAllWorkersModal] = useState(false);

    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const [showCostCenterPositionItem, setShowCostCenterPositionItem] = useState(false);

    const [filters, setFilters] = useState({ search: '', page: 1 });

    const [renderPositionItemsFilters, setRenderPositionItemsFilters] = useState({
        name: '',
        orderBy: 'name,ASC'
    });

    const [usersFilters, setUsersFilters] = useState({ search: '', page: 1 });

    const [createPeriodData, setCreatePeriodData] = useState({
        period: '',
        loadLastPayrrol: 'no',
        type: 'M',
        year: ''
    });

    const [payrrolFilters, setPayrrolFilters] = useState({
        page: 1
    });

    const [{ data, loading }] = useAxios({ url: `/cost-centers/${id}` }, { useCache: false });

    const [{ data: usersResponse, loading: loadingUsers }] = useAxios({ url: `/cost-centers/${id}/users/for-responsible`, params: usersFilters }, { useCache: false });

    const [{ data: workersResponse, loading: loadingWorkers }] = useAxios({ url: `/cost-centers/${id}/workers`, params: filters }, { useCache: false });

    const [{ data: allWorkersResponse, loading: loadingAllWorkers }, getAllWorkers] = useAxios({ url: `/cost-centers/${id}/workers`, params: { perPage: 1000 } }, { manual: true, useCache: false });

    const [{ data: payrrolsResponse, loading: payrrolsLoading }] = useAxios({ url: `/cost-centers/${id}/payrrols`, params: payrrolFilters }, { useCache: false });

    const [{ }, getExcel] = useAxios({ url: `/cost-centers/${id}/excel-for-items-load`, responseType: 'blob' }, { manual: true, useCache: false });

    const [{ data: createData, loading: createLoading }, createPayrrol] = useAxios({ url: `/cost-centers/${id}/payrrol`, method: 'POST' }, { manual: true, useCache: false });

    const [{ loading: payrrolResponsibleCreateLoading }, createPayrrolResponsible] = useAxios({ url: `/cost-centers/${id}/payrrol-responsible`, method: 'POST' }, { manual: true, useCache: false });

    const [{ data: renderPositionItemsData, loading: loadingRenderPositionItemsData }, getRenderPositionItemsData] = useAxios({ url: `/cost-centers/${id}/positions`, params: renderPositionItemsFilters }, { manual: true, useCache: false });

    useEffect(() => {
        if (showCostCenterPositionItem) getRenderPositionItemsData({ params: renderPositionItemsFilters })
    }, [showCostCenterPositionItem, renderPositionItemsFilters])

    useEffect(() => {
        if (data) {
            setCurrentResponsibles(data?.data?.payrrolResponsibles);
        };
    }, [data])

    useEffect(() => {
        if (workersResponse) {
            setCurrentWorkers((oldWorkers) => [...oldWorkers, ...workersResponse?.data]);
        }
    }, [workersResponse]);

    useEffect(() => {
        if (usersResponse) {
            setCurrentUsers((oldUsers) => [...oldUsers, ...usersResponse?.data]);
        }
    }, [usersResponse]);

    useEffect(() => {
        if (payrrolsResponse) {
            if (!selectedPayrrol) setSelectedPayrrol(payrrolsResponse?.data?.[0]);
            setCurrentPayrrols((oldPayrrols) => [...oldPayrrols, ...payrrolsResponse?.data]);
        }
    }, [payrrolsResponse]);

    useEffect(() => {
        setLoading?.({
            show: createLoading,
            message: `Iniciando el proceso`
        });
    }, [createLoading])

    const handleChange = (e) => {
        setCurrentWorkers([]);

        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value,
                page: 1
            }
        });
    }

    const handleCreateChange = (e) => {
        setCreatePeriodData(oldValue => {
            return {
                ...oldValue,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleClose = () => {
        setShowAllWorkersModal(false);
        setShowCreateProccessModal(true);
    }

    const handleCreate = () => {
        if (!createPeriodData?.loadLastPayrrol || !createPeriodData?.period) return alert('Todos los campos son requeridos.');
        getAllWorkers();
        setShowCreateProccessModal(false);
        setShowAllWorkersModal(true);
    }

    const handleContinue = () => {
        setShowAllWorkersModal(false);

        var period = createPeriodData?.period;
        var year = createPeriodData?.year;

        if (createPeriodData?.type === 'M') {
            period = createPeriodData?.period?.split('-')?.[1];
            year = createPeriodData?.period?.split('-')?.[0];
        }

        createPayrrol({
            data: {
                loadLastPayrrol: createPeriodData?.loadLastPayrrol,
                type: createPeriodData?.type,
                period,
                year
            }
        }).then((response) => {
            setShowSuccessModal(true);
        }).catch((e) => {
            setShowAllWorkersModal(true);
        });
    }

    const handleCloseSuccessModal = () => {
        setShowSuccessModal(false);
        setCurrentPayrrols((oldPayrrols) => {
            return [createData?.data, ...oldPayrrols]
        });

        setSelectedPayrrol(createData?.data);
    }

    const handleDeleteResponsible = (index) => {
        setCurrentResponsibles(update(currentResponsibles, { $splice: [[index, 1]] }));
    }

    const handleChangeUsersFilters = (e) => {
        setCurrentUsers([]);
        setUsersFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value,
                page: 1
            }
        });
    }

    const handleAddResponsible = (user) => {
        createPayrrolResponsible({
            data: {
                userId: user?.id
            }
        }).then((response) => {
            setCurrentResponsibles((oldResponsibles) => {
                return [...oldResponsibles, response?.data?.data];
            });
            setShowAddResponsiblesModal(false);
        });
    }

    const handleExcel = () => {
        getExcel().then(response => {
            const excelBlobUrl = URL.createObjectURL(response?.data);
            const aToDownload = document.getElementById('downloadLink');
            aToDownload.href = excelBlobUrl;
            aToDownload?.click();
            window.URL.revokeObjectURL(excelBlobUrl);
        })
    }

    const handleExcel2 = () => {
        getExcel({ url: `/cost-centers/${id}/excel-for-items-load-charges` }).then(response => {
            const excelBlobUrl = URL.createObjectURL(response?.data);
            const aToDownload = document.getElementById('downloadLink');
            aToDownload.href = excelBlobUrl;
            aToDownload?.click();
            window.URL.revokeObjectURL(excelBlobUrl);
        });
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-6 mb-3">
                    <h2>{data?.data?.name}</h2>
                    <p className="mb-2">
                        <img src={data?.data?.boss?.imagePath || profileImg} style={{ marginRight: 10, height: 40, width: 40, borderRadius: '100%' }} alt="" />
                        <span className="text-primary">{data?.data?.boss?.name}</span>
                    </p>
                    <p className="mb-2">
                        <b>Cantidad de trabajadores: <span className="text-primary">{data?.data?.workersCount}</span> </b>
                    </p>
                    <p className="mb-2">
                        <b>Responsables: <span className="text-primary">{data?.data?.payrrolResponsiblesCount}</span> </b>
                    </p>
                </div>
                <div className="col-md-8 mb-3">
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                Responsables:
                            </h5>
                        </div>
                        <div className="card-body">
                            <ul className="row align-items-center">
                                {currentResponsibles?.map((responsible, i) => {
                                    return (
                                        <PayrrolResponsibleComponent
                                            payrrolResponsible={responsible}
                                            onDelete={() => handleDeleteResponsible(i)}
                                            key={i}
                                        />

                                    )
                                })}
                                <li className="col-md-12 text-center my-5">
                                    <button className="btn btn-primary btn-block" onClick={() => setShowAddResponsiblesModal(true)}>
                                        Agregar
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <CostCenterNotificationEmailsComponent
                        costCenterId={id}
                    />
                </div>
                <div className="col-md-12 mb-3">
                    <CostCenterItemDescriptionsComponent
                        costCenterId={id}
                    />
                </div>
                <div className="col-md-12 mb-5">
                    <div className="card">
                        <div className="card-header">
                            <div className="row w-100 align-items-center justify-content-between">
                                <h5 className="col-5">
                                    <small style={{ fontSize: 10 }}>Configurar haberes</small>
                                    <br />
                                    Por cada cargo del centro de costo:
                                </h5>
                                <div className="col-3">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            Descargar excel
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={handleExcel}>
                                                Cargos y Haberes
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={handleExcel2}>
                                                Solo Cargos
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className="col-3">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Buscar cargo..."
                                        value={renderPositionItemsFilters?.name}
                                        onChange={(e) => setRenderPositionItemsFilters((oldValues) => ({ ...oldValues, name: e.target.value }))}
                                    />
                                </div>
                                <div className="col-1 text-end">
                                    <button className="btn btn-xs btn-primary" onClick={() => setShowCostCenterPositionItem(oldValue => !oldValue)}>
                                        {
                                            showCostCenterPositionItem ?
                                                <FaChevronUp />
                                                :
                                                <FaChevronDown />
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                        {
                            showCostCenterPositionItem &&
                            <div className="card-body custom-scrollbar scrollbar-primary" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                                {
                                    loadingRenderPositionItemsData ?
                                        <div className="spinner">
                                            <div className="double-bounce1 bg-primary"></div>
                                            <div className="double-bounce2 bg-primary"></div>
                                        </div>
                                        :
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th style={{ fontSize: 10 }} className="text-dark">
                                                        <strong>Nombre del Cargo</strong>
                                                    </th>
                                                    <th style={{ fontSize: 10 }} className="text-dark text-center">
                                                        <strong>Cantidad de trabajadores</strong>
                                                    </th>
                                                    <th style={{ fontSize: 10 }} className="text-dark text-end">
                                                        <strong>Acciones</strong>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    renderPositionItemsData?.map((position, i) => {
                                                        return (
                                                            <tr>
                                                                <td style={{ fontSize: 10 }} className="text-dark">
                                                                    <a href={`/centros-de-costos/${id}/cargo/${position?.id}/configurar-haberes`}>
                                                                        <strong>{position?.name}</strong>
                                                                    </a>
                                                                </td>
                                                                <td style={{ fontSize: 10 }} className="text-dark text-center">
                                                                    <strong>{position?.workersCount}</strong>
                                                                </td>
                                                                <td style={{ textAlign: 'end' }}>
                                                                    {/* <a href={`/centros-de-costos/${id}/cargo/${position?.id}/configurar-haberes`} className="btn btn-primary btn-xs">
                                                                        Configurar haberes
                                                                    </a> */}
                                                                    <a href={`/centros-de-costos/${id}/cargo/${position?.id}/configurar-haberes-2`} className="btn btn-primary btn-xs">
                                                                        Configurar haberes
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                }
                            </div>
                        }
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header px-2">
                            <div className="row w-100 align-items-center">
                                <div className="col-md-6 mb-3">
                                    <h6 className="mb-0">
                                        <FaRegUser /> Trabajadores
                                    </h6>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <input type="text" className="form-control" placeholder="Buscar..." name="search" onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="card-body px-0 py-0">
                            <ul className="custom-scrollbar scrollbar-primary" style={{ maxHeight: '60vh', overflow: 'auto' }}>
                                {
                                    currentWorkers?.map((worker, i) => {
                                        return (

                                            <li key={i} className="mb-2 px-3">
                                                <div className="d-flex align-items-center">
                                                    <img src={worker?.imagePath || profileImg} style={{ marginRight: 10, height: 40, width: 40, borderRadius: '100%' }} alt="" />
                                                    <div>
                                                        <p className="text-primary mb-0">{worker?.name}</p>
                                                        <p className="text-dark mb-0"><b>{worker?.position?.name}</b></p>
                                                        <p className="mb-0">{worker?.documentNumber}</p>
                                                    </div>
                                                </div>

                                            </li>
                                        )
                                    })
                                }
                                {
                                    !loadingWorkers && workersResponse?.meta?.last_page > filters?.page ?
                                        <li className="text-center my-3">
                                            <button className="btn btn-xs btn-primary" onClick={() => setFilters((oldFilters) => ({ ...oldFilters, page: oldFilters?.page + 1 }))}>
                                                Cargar mas
                                            </button>
                                        </li>
                                        :
                                        null
                                }
                                {
                                    loadingWorkers &&
                                    <li className="text-center my-5">
                                        Cargando...
                                    </li>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header px-2">
                            <div className="row w-100 align-items-center">
                                <div className="col-md-6 mb-3">
                                    <h6 className="mb-0">
                                        <FaProjectDiagram /> Historial de procesos
                                    </h6>
                                </div>
                                <div className="col-md-6 mb-3 text-md-end">
                                    <Dropdown autoClose="outside">
                                        <Dropdown.Toggle size="xs" variant={selectedPayrrol?.status?.variantColor || 'light'}>
                                            {
                                                selectedPayrrol ?
                                                    `${selectedPayrrol?.costCenter?.id}${selectedPayrrol?.type}${selectedPayrrol?.period}/${selectedPayrrol?.year}`
                                                    :
                                                    'Sin datos'
                                            }
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu style={{ maxHeight: '50vh', overflowY: 'auto' }} className="custom-scrollbar scrollbar-primary">
                                            {
                                                currentPayrrols?.map((payrrol, i) => {
                                                    return (
                                                        <Dropdown.Item onClick={() => setSelectedPayrrol(payrrol)} href="#" key={i} className="">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <p className="d-flex align-items-center m-0">
                                                                    {payrrol?.costCenter?.id}{payrrol?.type}{payrrol?.period}/{payrrol?.year} <div style={{ marginLeft: 5, width: 10, height: 10, borderRadius: '100%', background: payrrol?.status?.color }} />
                                                                </p>
                                                                {
                                                                    payrrol?.id === selectedPayrrol?.id ?
                                                                        '✔️'
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </Dropdown.Item>
                                                    )
                                                })
                                            }
                                            {
                                                !payrrolsLoading && payrrolsResponse?.meta?.last_page > payrrolFilters?.page ?
                                                    <Dropdown.Item autoClose={false} onClick={() => setPayrrolFilters((oldFilters) => ({ ...oldFilters, page: oldFilters?.page + 1 }))} href="#">
                                                        Cargar mas
                                                    </Dropdown.Item>
                                                    :
                                                    null
                                            }
                                            {
                                                payrrolsLoading &&
                                                <Dropdown.Item href="#">Cargando...</Dropdown.Item>
                                            }

                                            {
                                                currentPayrrols?.length === 0 &&
                                                <Dropdown.Item href="#">No se encontrarón resultados.</Dropdown.Item>
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className="card-body px-0 py-0">
                            {
                                selectedPayrrol ?
                                    <div className="p-3 animate__animated animate__fadeInUp">
                                        <p>
                                            <b>Tipo de proceso de nomina:</b> {periodTypeDescription[selectedPayrrol?.type]}
                                        </p>
                                        <p>
                                            <b>Mes:</b> {monthNames?.[selectedPayrrol?.period - 1]?.name}
                                        </p>
                                        <p>
                                            <b>Año:</b> {selectedPayrrol?.year}
                                        </p>
                                        <p>
                                            <b>Cantidad de trabajadores:</b> {selectedPayrrol?.workersCount}
                                        </p>

                                        <p>
                                            <RenderStatus status={selectedPayrrol?.status} />
                                        </p>

                                        <div className="text-center">
                                            <a
                                                href={`/gestionar-nomina/${selectedPayrrol?.id}`}
                                                className="btn btn-primary animate__animated animate__pulse animate__infinite"
                                            >
                                                Ver Detalles
                                            </a>
                                        </div>
                                        <br />
                                        {
                                            selectedPayrrol?.availableStatuses &&
                                            <ChangePayrrolStatusComponent
                                                payrrol={selectedPayrrol}
                                                onChangeStatus={(newPayrrol) => setSelectedPayrrol(newPayrrol)}
                                            />

                                        }
                                    </div>
                                    :
                                    <p className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
                                        No se encontrarón resultados.
                                    </p>

                            }
                        </div>
                    </div>
                </div>

            </div>
            <Modal animation="fade" show={showCreateProccessModal} onHide={() => setShowCreateProccessModal(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Iniciar Proceso
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <label className="text-primary">Seleccione el periodo</label>
                            <input
                                onChange={handleCreateChange}
                                value={createPeriodData?.period}
                                type="month"
                                className="form-control"
                                placeholder="periodo"
                                name="period"
                            />
                        </div>
                        <div className="col-md-12 mb-3">
                            <label className="text-primary">¿Desea cargar los valores del proceso anterior?</label>
                            <br />
                            <select name="loadLastPayrrol" onChange={handleCreateChange} className="form-control" value={createPeriodData?.loadLastPayrrol}>
                                <option value="si">Si</option>
                                <option value="no">No</option>
                            </select>
                            {
                                createPeriodData?.loadLastPayrrol === 'si' &&
                                <small className="d-block mt-2">El sistema tomará los haberes de los trabajadores del periodo anterior y se los asignara a este. Mas tarde en el detalle podrá modificarlos y ajustarlos.</small>
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCreateProccessModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleCreate}>
                        Iniciar Proceso
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal animation="fade" show={showAllWorkersModal} onHide={handleClose} size="lg" scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Listado de trabajadores que se cargaran en el proceso:
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="custom-scrollbar scrollbar-primary">
                    <ul>
                        {
                            allWorkersResponse?.data?.map((worker, i) => {
                                return (

                                    <li key={i} className="mb-2 px-3">
                                        <div className="d-flex align-items-center">
                                            <img src={worker?.imagePath || profileImg} style={{ marginRight: 10, height: 40, width: 40, borderRadius: '100%' }} alt="" />
                                            <div>
                                                <p className="text-primary mb-0">{worker?.name}</p>
                                                <p className="text-dark mb-0"><b>{worker?.position?.name}</b></p>
                                                <p className="mb-0">{worker?.documentNumber}</p>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })
                        }
                        {
                            loadingAllWorkers &&
                            <li className="text-center my-5">
                                Cargando...
                            </li>
                        }
                    </ul>
                    <p className="text-center">
                        <b>
                            Si toda la información esta correcta por favor pulse en continuar.
                        </b>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Volver
                    </Button>
                    <Button variant="primary" onClick={handleContinue}>
                        Continuar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal animation="fade" show={showSuccessModal} onHide={handleCloseSuccessModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        ¡🎉 Enhorabuena el proceso se ha iniciado exitosamente!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center">
                        <button onClick={handleCloseSuccessModal} className="btn btn-danger">
                            Ver mas tarde
                        </button>
                        <br />
                        <br />
                        <a
                            href={`/gestionar-nomina/${createData?.data?.id}`}
                            className="btn btn-primary animate__animated animate__pulse animate__infinite"
                        >
                            Ver el proceso
                        </a>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal animation="fade" show={showAddResponsiblesModal} onHide={() => setShowAddResponsiblesModal(false)} size="lg" scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Seleccione el usuario que quiere asignar como responsable:
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="custom-scrollbar scrollbar-primary">
                    <input
                        type="text"
                        className="form-control"
                        value={usersFilters?.search}
                        onChange={handleChangeUsersFilters}
                        name="search"
                        placeholder="Buscar"
                    />
                    <br />
                    <ul>
                        {
                            currentUsers?.map((user, i) => {
                                return (

                                    <li key={i} className="mb-2 px-3" onClick={() => handleAddResponsible(user)} style={{ cursor: 'pointer' }}>
                                        <div className="d-flex align-items-center">
                                            <img src={user?.imagePath || profileImg} style={{ marginRight: 10, height: 40, width: 40, borderRadius: '100%' }} alt="" />
                                            <div>
                                                <p className="text-primary mb-0">{user?.name}</p>
                                                <p className="text-dark mb-0"><b>{user?.position?.name}</b></p>
                                                <p className="mb-0">{user?.documentNumber}</p>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })
                        }
                        {
                            !loadingUsers && usersResponse?.meta?.last_page > usersFilters?.page ?
                                <li className="text-center">
                                    <button className="btn btn-primary btn-xs" onClick={() => setUsersFilters(oldFilters => {
                                        return {
                                            ...oldFilters,
                                            page: oldFilters?.page + 1
                                        }
                                    })}>
                                        Cargar más
                                    </button>
                                </li>
                                :
                                null
                        }
                        {
                            currentUsers?.length === 0 && !loadingUsers ?
                                <li className="text-center my-5">
                                    No se encontrarón resultados.
                                </li>
                                :
                                null
                        }
                        {
                            loadingAllWorkers &&
                            <li className="text-center my-5">
                                Cargando...
                            </li>
                        }
                    </ul>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default CostCentersUpdate;