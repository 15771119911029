import { FaRegTrashAlt } from "react-icons/fa";
import profileImg from '../../images/profile.png';
import swal from "sweetalert";
import useAxios from "../../hooks/useAxios";
import update from 'immutability-helper';

const PayrrolResponsibleComponent = ({ payrrolResponsible, onDelete }) => {

    const [{ loading }, deleteResponsible] = useAxios({ url: `/cost-centers/payrrol-responsibles/${payrrolResponsible?.id}`, method: 'DELETE' }, { manual: true, useCache: false });

    const handleDelete = () => {
        swal({
            title: "¿Estas Seguro(a)?",
            text: "¿Quieres remover este responsable?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) deleteResponsible().then(() => onDelete?.());
        })
    }

    return (
        <li className="col-md-12 mb-3">
            <div className="d-flex align-items-center">
                <img src={payrrolResponsible?.responsible?.imagePath || profileImg} style={{ marginRight: 10, height: 40, width: 40, borderRadius: '100%' }} alt="" />
                <div>
                    <p className="text-primary mb-0">{payrrolResponsible?.responsible?.name}</p>
                    <p className="text-dark mb-0"><b>{payrrolResponsible?.responsible?.position?.name}</b></p>
                    <p className="mb-0">{payrrolResponsible?.responsible?.documentNumber}</p>
                </div>
                <button className="ms-auto btn btn-danger btn-xs" onClick={handleDelete}>
                    {
                        loading ?
                            <div className="spinner" style={{ width: 12, height: 12 }}>
                                <div className="double-bounce1 bg-light"></div>
                                <div className="double-bounce2 bg-light"></div>
                            </div>
                            :
                            <FaRegTrashAlt />
                    }
                </button>
            </div>
        </li>
    )
}

export default PayrrolResponsibleComponent;