import { dateFine } from "../../../util/Utilities";
import CostCenterPayrrolStatusComponent from "../../CostCenterPayrrolStatusComponent";
import DateFormatter from "../../DateFormatter";

const CostCenterSoftPayrrolColumns = [
    {
        Label: () => 'Id',
        accessor: 'id'
    },
    {
        Label: () => 'Mes',
        Component: ({ value }) => `${value?.period}`
    },
    {
        Label: () => 'Año',
        Component: ({ value }) => `${value?.year}`
    },
    {
        Label: () => 'Trabajadores',
        Component: ({ value }) => `${value?.workersCount}`
    },
    {
        Label: () => 'Estado',
        Component: ({ value }) => {
            return <div className={`btn btn-xs btn-${value?.statusVariantColor}`}>
                {value?.statusName}
            </div>
        }
    },
    {
        Label: () => 'Fecha de Creación',
        Component: ({ value }) => `${value?.created_at}`
    },
    {
        Label: () => 'Acciones',
        Component: ({ value }) => <a href={`/gestionar-nomina/${value?.id}`} className="btn btn-xs btn-primary">
            Ver detalles
        </a>
    }
];

export default CostCenterSoftPayrrolColumns;

