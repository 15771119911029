const CostCentersFiltersComponent = ({ filters, setFilters }) => {

    const handleChange = (e) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value,
                page: 1
            }
        })
    }

    return (
        <div className="card p-4">
            <label htmlFor="" className="text-primary">
                Buscar Por:
            </label>
            <input
                type="text"
                className="form-control"
                value={filters?.search}
                name="search"
                onChange={handleChange}
                placeholder="Id, Código, Nombre, Jefe..."
            />
        </div>
    )
}

export default CostCentersFiltersComponent;